.css-rmz4vk{
    position: absolute!important;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: 90vh;
    left: 0;
    background-color: #000000a1;
}

.css-1rn30mb-MuiCircularProgress-root,
.css-ol7wxs-MuiTypography-root{
    color: white!important;

}