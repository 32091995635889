.welcome-component {
  position: absolute;
  background: #000000a1;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1;
}

.welcome-component.show {
  opacity: 0;
  z-index: -1;
  transition: opacity 2s, z-index 0s 1.5s;
}

.welcome-component .component-centered {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.welcome-component .component-centered .card {
  height: 250px;
  border-radius: 10px;
}

.welcome-component .component-centered .card-body {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;
}

.dots-spinner :first-child {
  position: sticky !important;
  transform: translate(0, 0);
}

.welcome-component .component-centered .logo-client-container {
  margin: 0 auto;
}

.welcome-component .component-centered .hand-paper {
  font-size: 1.3rem;
  color: #133e53;
  display: inline-block;
  margin-left: -15px;
  animation: regards 1.2s infinite;
  animation-direction: alternate;
}

@keyframes regards {
  0% {
    transform: translate(25px, -50px) rotate(45deg);
  }
  100% {
    transform: translate(25px, -50px) rotate(-30deg);
  }
}

@media screen and (max-width: 425px) {
  .welcome-component .component-centered .hand-paper {
    transform: translate(0px, 0px);
    margin: auto;
    text-align: center;
  }
  @keyframes regards {
    0% {
      transform: translate(0px, -30px) rotate(45deg);
    }
    100% {
      transform: translate(0px, -30px) rotate(-30deg);
    }
  }
}
