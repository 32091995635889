.firststep{
    position: absolute;
    top:30%;
    padding: 45px;
    left: 0;
    right: 20px;
}

/* Chrome, Safari, Edge, Opera */
.input-code input::-webkit-outer-spin-button,
.input-code input::-webkit-inner-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-code input[type=number] {
  -moz-appearance: textfield;
  padding: 5px;
  text-align:center;
}

.request-code{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 0.7rem;
}

.request-code a{
    color: #5475ca;
    text-decoration: revert;
}

.firststep .card{
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 0rem 1.4375rem 2.8125rem -0.6875rem rgb(20 20 20 / 25%);
    text-align: center!important;
    padding: 3rem; 
    margin: auto;
    max-width: 30rem;
}

.firststep .button-container{
    margin-top: 20px;
}

.firststep .btn-info{
    background-color: #6a89fb;
    border-color: #6a89fb;
    color: white;
}

.firststep .btn-info:not(:disabled):not(.disabled):active{
    color: #fff;
    background-color: #365FF9;
    border-color: #365ff9;
}

.firststep .btn-info:focus,
.firststep .btn-info:hover,
.firststep .btn-info:not(:disabled):not(.disabled):active:focus{
    box-shadow: 0 0 0 0.2rem rgb(120 172 255 / 50%);
}

.card-subtitle{
    font-size: 0.9rem;
}

.confirmation-code-box input[type="tel"] {
    font-family: inherit;
}

@media screen and (max-width: 425px) {
    .input-code input[type=number] {
        padding: 0;
    }

    .firststep .card{
        padding: 1rem;
    }

    .card-subtitle{
        font-size: 0.7rem;
    }
}