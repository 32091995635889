.secondstep-container {
  overflow: auto;
  justify-content: center;
  padding-bottom: 5rem;
  height: 72vh;
}

.logo-client-container {
  justify-content: center;
  width: 100%;
  margin: 0;
}

.welcome-name {
  margin: 30px 0 10px 0;
}

.balance-container {
  margin-top: 20px;
}

.balance-container .card {
  border-radius: 0.7rem;
  box-shadow: 0rem 1.4375rem 2.8125rem -0.6875rem rgb(20 20 20 / 25%);
}

.balance-container .card h4 {
  color: #a6aba9;
}

.balance-container .card h5 {
  font-size: 1rem;
}

.balance-container .card h6 {
  font-size: 0.7rem;
}

.balance-container .card .price p {
  font-size: 0.5rem;
}

.balance-container .discount {
  color: rgba(231, 33, 116, 0.993);
}

.balance-container .credit {
  color: rgba(60, 216, 120, 0.993);
}

.balance-container .container-last-tx {
  padding: 0 10px 0 10px;
}
.transactions-amount {
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
}

@media screen and (min-width: 426px) {
  .transactions-amount {
    font-size: 14px;
  }
}
