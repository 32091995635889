.message-wrapper {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
}

.message-text {
  font-size: 16px;
  text-align: center;
  font-weight: normal;
}

.message-icon {
  font-size: 26px;
}

@media (min-width: 768px) {
  .message-wrapper {
    padding: 10%;
  }

  .message-text {
    font-size: 20px;
  }

  .message-icon {
    font-size: 36px;
  }
}
