#root {
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 35px;
  padding-top: 1em;
  background-color: #2f2f2f;
}

.App {
  text-align: center;
  background-color: #2f2f2f;
  width: 100%;
  height: 100vh;
}

h1,h2,h3,h4{
  color:#02428B;
  font-family: 'Montserrat', sans-serif;
}

h1{
  font-size: 40px;
  text-align: center;
  font-weight: 800;
}

h2{
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  color:#103547;
}

h3{
  font-size: 15px;
  font-weight: 600;
}

h4{
  font-size: 0.9rem;
  color:#373A39;
  font-weight: 500;
}

.container{
  max-width: 768px;
  background-color: #f7f7f7;
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: linear-gradient(310deg, rgba(237, 237, 237, 0.8), rgba(211, 211, 211, 0.8)),url(../img/curved2.jpg);
  box-shadow: 0rem 1.4375rem 2.8125rem -0.6875rem rgb(20 20 20 / 25%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.modal-content{
background-color: transparent;
border: 0;
}

.input-code{
  --bs-gutter-x: 1rem;
}

.css-rmz4vk{
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

@media screen and (max-width: 425px) {
  .input-code{
    --bs-gutter-x: 0.5rem!important;
  }
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  #root {
    padding-top: 0;
  }
  .confirmation-code-box input[type="tel"] {
    width: 16% !important;
    font-size: 1.4em;
    height: 40px !important;
  }
}