.card-container .card{
    min-height: 100px;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
    background: linear-gradient(310deg, rgba(20, 23, 39, 0.8), rgba(58, 65, 111, 0.8)),url(../img/curved14.jpg);
    box-shadow: 0rem 1.4375rem 2.8125rem -0.6875rem rgb(20 20 20 / 25%);
    padding: 10px;
}

.card-container .MuiSwitch-colorPrimary.Mui-checked{
    color: #fba234!important;
}

.card-container .MuiSwitch-track{
    background-color: white!important;
}

.card-container .card-subtitle{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.card-container .card-subtitle h4{
    color: white;
    font-size: 11px;
}

.card-container .top-info-card{
    margin-top: 35px;
}

.card-container .number-card{
    margin-top: 30px;
}

.card-container .number-card h4{
    color: white;
}

.card-container .top-info-card h4{
    font-size: 11px;
    color: white;
    opacity: 0.8;
}

.card-container .info-card h4{
    font-size: 14px;
    color: white;
}

.card-container .copy-icon{
    font-size: 0.7rem;
    color: white;
    margin-left: 5px;
    cursor: pointer;
}