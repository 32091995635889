.header-container {
  padding: 10px;
}

.header-container .card.secondary-card {
  width: 100%;
  border-radius: 15px;
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0rem 0rem 0.0625rem 0.0625rem rgb(255 255 255 / 90%),
    0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
  position: relative;
  margin: 0 auto;
  padding: 10px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-container .logo-gbc {
  margin: 10px 0;
  text-align: center;
}

.header-container .secondary-card h6 {
  font-size: 0.7rem !important;
  justify-content: center;
  text-align: center;
}

.header-container .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 25px;
  font-size: 0.7rem;
  text-transform: capitalize;
  width: 100%;
  border-radius: 20px;
}

.header-container .css-heg063-MuiTabs-flexContainer {
  justify-content: center;
  height: 26px;
}

.header-container .css-1aquho2-MuiTabs-indicator {
  display: none;
}

.header-container .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #6c6c6c;
}

.header-container .btn-signout {
  /* font-size: 0.5rem; */
  padding: 5px;
  border-radius: 10px;
  text-transform: capitalize;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
}

.client-logo {
  aspect-ratio: 3/2;
  width: 50% !important;
  object-fit: contain;
}

@media screen and (max-width: 425px) {
  .header-container .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 0.5rem;
  }

  .header-container .btn-signout {
    /* font-size: 0.5rem; */
    /* padding: 5px; */
  }
  .header-container .btn-signout .btn-signout-text {
    display: none;
  }
}

@media screen and (min-width: 426px) {
  .client-logo {
    width: 25% !important;
  }
}
