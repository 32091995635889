.footer {
  height: 75px;
  margin-top: 2em;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #ffffff7a;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.footer .card.primary-card {
  width: 100%;
  background-color: transparent;
  border: 0;
}

.footer .card.primary-card {
  display: flex;
}

.footer .logo-gbc {
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 13px;
}

.footer .logo-gbc h6 {
  margin-right: 5px;
}

.footer .logo-gbc h6,
.footer .logo-gbc h6 {
  color: #787878;
  font-size: 0.7rem;
}

.footer .logo-gbc.right {
  justify-content: right;
  padding-right: 3rem;
}

@media screen and (max-width: 425px) {
  .footer .logo-gbc h6,
  .footer .logo-gbc h6 {
    font-size: 0.5rem;
  }

  .footer .logo-gbc {
    justify-content: center;
    padding-right: 0;
  }
}
